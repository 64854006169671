export enum BeltRank {
  whiteGrauZero,
  whiteGrauOne,
  whiteGrauTwo,
  whiteGrauThree,
  whiteGrauFour,
  blueGrauZero,
  blueGrauOne,
  blueGrauTwo,
  blueGrauThree,
  blueGrauFour,
  purpleGrauZero,
  purpleGrauOne,
  purpleGrauTwo,
  purpleGrauThree,
  purpleGrauFour,
  brownGrauZero,
  brownGrauOne,
  brownGrauTwo,
  brownGrauThree,
  brownGrauFour
}

export namespace BeltRanks {
  /**
   * Converts a BeltRank enum value to its SCREAMING_SNAKE_CASE string representation.
   * @param rank The BeltRank enum value.
   * @returns The SCREAMING_SNAKE_CASE string.
   */
  export function toString(rank: BeltRank): string {
    const enumKey = BeltRank[rank];
    return toScreamingSnakeCase(enumKey);
  }

  /**
   * Converts a SCREAMING_SNAKE_CASE string to its corresponding BeltRank enum value.
   * @param value The SCREAMING_SNAKE_CASE string.
   * @returns The corresponding BeltRank enum value, or undefined if not found.
   */
  export function fromString(value: string): BeltRank | undefined {
    for (const key in BeltRank) {
      // TypeScript enums are bidirectional, so we need to filter out numeric keys
      if (isNaN(Number(key))) {
        if (toScreamingSnakeCase(key) === value) {
          return BeltRank[key as keyof typeof BeltRank];
        }
      }
    }
    return undefined;
  }

  /**
   * Utility function to convert camelCase or PascalCase strings to SCREAMING_SNAKE_CASE.
   * @param str The input string in camelCase or PascalCase.
   * @returns The converted SCREAMING_SNAKE_CASE string.
   */
  function toScreamingSnakeCase(str: string): string {
    return str
      .replace(/([a-z0-9])([A-Z])/g, '$1_$2') // Add underscore between lowercase and uppercase
      .replace(/([A-Z])([A-Z][a-z])/g, '$1_$2') // Add underscore between two uppercase letters when the second is followed by lowercase
      .toUpperCase();
  }
}

export interface AppUser {
  userID: number;
  authUserID: string;
  displayName?: string | null;
  gym?: Gym | null;
  beltRank?: BeltRank | null;
  profileImageURL?: string | null;
}

export interface Gym {
  gymID: number;
  name: string;
  address: string;
  mobile?: string | null;
}
