import React, {useEffect, useState} from 'react';
import { Typography, Divider, Box, Paper } from '@mui/material';
import {
    Star,
    People,
    BarChart,
    ArrowBackIos,
    ArrowForwardIos
} from '@mui/icons-material';
import ProfileSummary from './ProfileSummary';
import { startOfWeek, addDays, format } from 'date-fns';
import {useUserByAuthID} from "../hooks/user-hooks";
import supabaseClient from "../lib/supabase-client/supabaseClient";

const HomePage: React.FC = () => {
    // const supabase = useSupabaseClient()
    // const user = useUser()
    const [authID, setAuthID] = useState<string | null>(null)

    const { user, loading, error} = useUserByAuthID(authID)

    useEffect(() => {
        fetchAuthID();
    }, []);

    const fetchAuthID = async () => {
        const { data: { session } } = await supabaseClient.auth.getSession();
        if (session?.user?.id) {
            setAuthID(session.user.id);
        }
    };

    if (!authID) {
        return <div>Loading...</div>;
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!user) {
        return <div>User not found</div>;
    }

    const today = new Date();
    const startDate = startOfWeek(addDays(today, -7 * 11), { weekStartsOn: 0 }); // Start from 12 weeks ago, week starts on Sunday

    // Generate a 7x12 grid for the contribution graph with month and day labels
    const generateGrid = () => {
        const grid = [];
        let currentDate = startDate;
        for (let col = 0; col < 7; col++) {
            const column = [];
            for (let row = 0; row < 6; row++) {
                const date = addDays(currentDate, row);
                column.push(
                    <Box
                        key={`${row}-${col}`}
                        sx={{
                            width: 29,
                            height: 29,
                            backgroundColor: Math.random() > 0.5 ? '#56DB81' : '#71717A',
                            margin: 0.25,
                            borderRadius: '8px',
                        }}
                    />
                );
            }
            grid.push(
                <Box key={col} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {column}
                </Box>
            );
            currentDate = addDays(currentDate, 7); // Move to the next week
        }
        return grid;
    };

    return (
        <Box sx={{ backgroundColor: '#121212', color: '#fff', height: '100vh' }}>
            <Box sx={{ paddingX: 2 }}>
                <ProfileSummary userName={user.displayName ?? 'Unknown'} gymName={`${user.gym?.name}` ?? ''} beltRank={user.beltRank} avatarUrl={user.profileImageURL ?? 'placeholder'}/>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
                <Paper sx={{ padding: 2, backgroundColor: '#1e1e1e', color: '#fff', width: '100%' , paddingX: 5, borderRadius: '12px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ textAlign: 'center' }}>
                            <Star />
                            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>31</Typography>
                            <Typography variant="body2">전체</Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <People />
                            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>5</Typography>
                            <Typography variant="body2">승급 이후</Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <BarChart />
                            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>3</Typography>
                            <Typography variant="body2">이번 달</Typography>
                        </Box>
                    </Box>
                </Paper>
            </Box>

            <Box sx={{ padding: 2 }}>
                <Paper  sx={{ padding: 5, backgroundColor: '#1e1e1e', color: '#fff', textAlign: 'center', borderRadius: '12px' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{format(new Date(), 'MMM yyyy')}</Typography>
                    <Divider sx={{height: 10}}/>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <ArrowBackIos sx={{ display: 'flex', color: '#71717A' }} />
                            <Box sx={{ display: 'flex', marginX: '15px' }}>
                                {generateGrid()}
                            </Box>
                            <ArrowForwardIos sx={{ display: 'flex', color: '#71717A' }} />
                        </Box>
                    </Box>
                </Paper>
            </Box>

            <Box sx={{ padding: 2 }}>
                <Paper sx={{ padding: 2, backgroundColor: '#1e1e1e', color: '#fff', borderRadius: '12px'  }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>랭킹</Typography>
                    <Typography variant="body1" sx={{marginLeft: '30px'}}>1. User A - 1200 points</Typography>
                    <Typography variant="body1" sx={{marginLeft: '30px'}}>2. User B - 1100 points</Typography>
                    <Typography variant="body1" sx={{marginLeft: '30px'}}>3. User C - 1000 points</Typography>
                </Paper>
            </Box>
        </Box>
    );
};

export default HomePage;
